
html, body, #root{
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
  font-size: 14px;
}

[disabled] {
  opacity: 0.75 !important;
  pointer-events: none !important;
}

/* keyword styles */
.containsKeywords{

}
.containsKeywords .foundKeyword{
	/*background: rgba(241, 196, 15, 0.25);
	background: rgba(243, 156, 18, 0.25);
	background: rgba(46, 204, 113, 0.25);*/
	border-radius: 3px;
	/*text-decoration: 1px dotted underline;*/
}

.tripleLineClip{
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.doubleLineClip{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.singleLineClip{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}


/* styles for when embedding html from reddit */
.embedTarget a{
  text-decoration: underline;
}

.embedTarget p{
  margin-bottom: 15px;
}

.embedTarget ol, .embedTarget ul{
	padding-left: 20px;
}

.embedTarget ol li{
	list-style: decimal;
}

.embedTarget ul li{
	list-style: disc;
}

/* custom (skinny) scrollbar */
::-webkit-scrollbar {
    width: 5px;
    height: 11px;
}
::-webkit-scrollbar-track {
	border-radius: 2px;
  	background: rgba(0,0,0, 0.03);
}
::-webkit-scrollbar-thumb {
    border-radius: 2px;
  	background: rgba(0,0,0, 0.1);
  	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
}
::-webkit-scrollbar-thumb:horizontal:hover,
::-webkit-scrollbar-thumb:vertical:hover { 
  	background: rgba(0,0,0, 0.2);
} 


.darkStyle *::-webkit-scrollbar-track {
    background: rgba(255,255,255, 0.03);
}
.darkStyle *::-webkit-scrollbar-thumb {
    background: rgba(255,255,255, 0.1);
}
.darkStyle *::-webkit-scrollbar-thumb:horizontal:hover,
.darkStyle *::-webkit-scrollbar-thumb:vertical:hover { 
    background: rgba(255,255,255, 0.2);
} 




/* make sure popover is on top, even over modal */
.react-tiny-popover-container{
  z-index: 100;
}

/* react toggle */
.toggle-darkmode .react-toggle-track {
  background-color: rgba(31, 41, 55, 0.75);
}
.toggle-darkmode:hover:not(.toggle-darkmode.react-toggle--disabled) .react-toggle-track {
  background-color: rgba(31, 41, 55, 1);
  /* doesnt work yet */
}

.toggle-darkmode.react-toggle--checked .react-toggle-track {
  background-color: rgba(8, 145, 178, 0.5)
}
.toggle-darkmode.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: rgba(8, 145, 178, 0.75)
}

.toggle-darkmode .react-toggle-thumb {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
  width: 19px;
  height: 19px;
  margin: 1px;
}
.toggle-darkmode .react-toggle-track-x{
  height: 19px;
  right: 12px;
}
.toggle-darkmode .react-toggle-track-check{
  height: 19px;
  left: 5px;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}