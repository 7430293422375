
.fancyloader{
  width: 200px;
  height: 200px;
  opacity: 0.05;
}

.fancyloader .hex-brick{
  background: #ABF8FF;
  width: 30px;
  height: 17px;
  position: absolute;
  top: 5px;
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  -webkit-animation-name: fade;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
}

.fancyloader .h2{
  transform: rotate(60deg);
  -webkit-transform: rotate(60deg);
}

.fancyloader .h3{
  transform: rotate(-60deg);
  -webkit-transform: rotate(-60deg);
}

.fancyloader .gel{
  height: 30px;
  width: 30px;  
  transition: all .3s;
  -webkit-transition: all .3s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.fancyloader .center-gel{
  margin-left: -15px;
  margin-top: -15px;
  
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  -webkit-animation-name: pulse;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
}

.fancyloader .c1{
  margin-left: -47px;
  margin-top: -15px;
}

.fancyloader .c2{
  margin-left: -31px;
  margin-top: -43px;
}

.fancyloader .c3{
  margin-left: 1px;
  margin-top: -43px;
}

.fancyloader .c4{
  margin-left: 17px;
  margin-top: -15px;
}
.c5{
  margin-left: -31px;
  margin-top: 13px;
}

.fancyloader .c6{
  margin-left: 1px;
  margin-top: 13px;
}

.fancyloader .c7{
  margin-left: -63px;
  margin-top: -43px;
}

.fancyloader .c8{
  margin-left: 33px;
  margin-top: -43px;
}

.fancyloader .c9{
  margin-left: -15px;
  margin-top: 41px;
}

.fancyloader .c10{
  margin-left: -63px;
  margin-top: 13px;
}

.fancyloader .c11{
  margin-left: 33px;
  margin-top: 13px;
}

.fancyloader .c12{
  margin-left: -15px;
  margin-top: -71px;
}

.fancyloader .c13{
  margin-left: -47px;
  margin-top: -71px;
}

.fancyloader .c14{
  margin-left: 17px;
  margin-top: -71px;
}

.fancyloader .c15{
  margin-left: -47px;
  margin-top: 41px;
}

.fancyloader .c16{
  margin-left: 17px;
  margin-top: 41px;
}

.fancyloader .c17{
  margin-left: -79px;
  margin-top: -15px;
}

.fancyloader .c18{
  margin-left: 49px;
  margin-top: -15px;
}

.fancyloader .c19{
  margin-left: -63px;
  margin-top: -99px;
}

.fancyloader .c20{
  margin-left: 33px;
  margin-top: -99px;
}

.fancyloader .c21{
  margin-left: 1px;
  margin-top: -99px;
}

.fancyloader .c22{
  margin-left: -31px;
  margin-top: -99px;
}

.fancyloader .c23{
  margin-left: -63px;
  margin-top: 69px;
}

.fancyloader .c24{
  margin-left: 33px;
  margin-top: 69px;
}

.fancyloader .c25{
  margin-left: 1px;
  margin-top: 69px;
}

.fancyloader .c26{
  margin-left: -31px;
  margin-top: 69px;
}

.fancyloader .c27{
  margin-left: -79px;
  margin-top: -15px;
}

.fancyloader .c28{
  margin-left: -95px;
  margin-top: -43px;
}

.fancyloader .c29{
  margin-left: -95px;
  margin-top: 13px;
}

.fancyloader .c30{
  margin-left: 49px;
  margin-top: 41px;
}

.fancyloader .c31{
  margin-left: -79px;
  margin-top: -71px;
}

.fancyloader .c32{
  margin-left: -111px;
  margin-top: -15px;
}

.fancyloader .c33{
  margin-left: 65px;
  margin-top: -43px;
}

.fancyloader .c34{
  margin-left: 65px;
  margin-top: 13px;
}

.fancyloader .c35{
  margin-left: -79px;
  margin-top: 41px;
}

.fancyloader .c36{
  margin-left: 49px;
  margin-top: -71px;
}

.fancyloader .c37{
  margin-left: 81px;
  margin-top: -15px;
}

.fancyloader .r1{
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: .2s;
  -webkit-animation-name: pulse;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: .2s;

  -webkit-transform: scale(0.01);
  transform: scale(0.01);
}

.fancyloader .r2{
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: .4s;
  -webkit-animation-name: pulse;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: .4s;

  -webkit-transform: scale(0.01);
  transform: scale(0.01);
}

.fancyloader .r3{
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: .6s;
  -webkit-animation-name: pulse;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: .6s;

  -webkit-transform: scale(0.01);
  transform: scale(0.01);
}

.fancyloader .r1 > .hex-brick{
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: .2s;
  -webkit-animation-name: fade;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: .2s;
}

.fancyloader .r2 > .hex-brick{
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: .4s;
  -webkit-animation-name: fade;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: .4s;
}

.fancyloader .r3 > .hex-brick{
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: .6s;
  -webkit-animation-name: fade;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: .6s;
}


@keyframes pulse{
  0%{
    -webkit-transform: scale(0.01);
    transform: scale(0.01);
  }
  
  50%{
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  
  100%{
    -webkit-transform: scale(0.01);
    transform: scale(0.01);
  }
}

@keyframes fade{
  0%{
    background: #ABF8FF;
  }
  
  50%{
    background: #90BBBF;
  }
  
  100%{
    background: #ABF8FF;
  }
}

@-webkit-keyframes pulse{
  0%{
    -webkit-transform: scale(0.01);
    transform: scale(0.01);
  }
  
  50%{
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  
  100%{
    -webkit-transform: scale(0.01);
    transform: scale(0.01);
  }
}

@-webkit-keyframes fade{
  0%{
    background: #ABF8FF;
  }
  
  50%{
    background: #389CA6;
  }
  
  100%{
    background: #ABF8FF;
  }
}